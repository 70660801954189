import ReactPortal from './react-portal';

import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
export interface ModalProps {
  portalId: string;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: React.ReactNode;
  showCloseButton?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    portalId,
    open,
    setOpen,
    title = '',
    showCloseButton = true,
    className,
    headerClassName,
    titleClassName
  } = props;
  if (!open) return null;

  return (
    <ReactPortal wrapperId={portalId}>
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-primary bg-opacity-60 z-50"
        onClick={() => setOpen(false)}
      />
      <section
        className={twMerge(
          'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white',
          className
        )}
      >
        {(showCloseButton || title) && (
          <div
            className={twMerge(
              'flex px-6 py-4 border-b border-gray-400',
              headerClassName
            )}
          >
            {title && (
              <h3 className={twMerge('text-lg font-light', titleClassName)}>
                {title}
              </h3>
            )}
            {showCloseButton && (
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="ml-auto"
              >
                <Icon name="close" size={16} />
              </button>
            )}
          </div>
        )}
        {children}
      </section>
    </ReactPortal>
  );
};
